import React from 'react';
import Header from '../_components/Header';
import Button from '../_components/Button';
import Footer from '../_components/Footer';
import ImageHero from './image1.jpg';
import Image2 from './image2.jpg';
import './RatesPage.css';
import { FormattedMessage } from 'react-intl';
import Media from "react-media";
import HeaderMobile from '../_components/HeaderMobile';
import { Link } from 'react-router-dom';

class RatesPage extends React.Component {
  render() {
    return (
      <div className="Rates">
        <Media query={{ minWidth: 768 }}>
          {matches =>
            matches ? (
              <Header />
            ) : (
              <HeaderMobile />
            )}
        </Media>
        <section className="Rates-hero" 
          style={{
            height: '350px',
            width: '100%',
            backgroundImage: `url(${ImageHero})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top'
          }}>
        </section>
        <section className="Rates-banner">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <p>
                  <FormattedMessage id="rates.banner" defaultMessage="APERTURA DE INSCRIPCIONES 20 DE DICIEMBRE" />
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="Rates-info">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                  <h3 className="Rates-title">
                    <FormattedMessage id="rates.title" defaultMessage="Tarifas" />
                  </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <article className="Rates-info-article">
                  <p className="Rates-info-text text-justify mt-4">
                    Las inscripciones se abrirán en la página web desde el Domingo 20 de diciembre de 2024 hasta el
                    Domingo 20 de Abirl (con opción a camiseta conmemorativa del evento), o el Martes 1 de Mayo (sin
                    opción a camiseta conmemorativa) o hasta completar aforo máximo de 1000 participantes.
                  </p>
                  <h6>TARIFAS 4 PICOS ROAD</h6>
                  <table className="table table-responsive">
                    <tbody>
                    <tr>
                      <th className="scope">
                        Desde el 20 de diciembre de 2024 hasta el 30 de enero de 2025 o completar las 1000 plazas
                        disponibles.
                      </th>
                      <td>37 € iva incluido</td>
                    </tr>
                    <tr>
                      <th className="scope">
                        Desde el 1 de enero hasta el 20 de abril de 2025 (con opción a camiseta) o hasta completar las
                        1000 plazas.
                      </th>
                      <td>45 € iva incluido</td>
                    </tr>
                    <tr>
                      <th className="scope">
                        Desde el 21 de Abril hasta el 1 de Mayo (sin opción a camiseta) o hasta completar las 1000
                        plazas disponibles.
                      </th>
                      <td>45 € iva incluido</td>
                    </tr>
                    </tbody>
                  </table>
                  <h6>TARIFAS PRUEBAS COMBINADAS</h6>
                  <table className="table table-responsive">
                    <tbody>
                    <tr>
                      <th className="scope">Desde 20 de Diciembre de 2024 hasta el 31 de enero de 2025 o hasta completar
                        los primeras 800 plazas
                      </th>
                      <td>69€</td>
                    </tr>
                    <tr>
                      <th className="scope">Desde el 1 de febrero de 2025 hasta el 20 de abril (con opción a camiseta) o
                        completar las 1500 plazas
                      </th>
                      <td>82€</td>
                    </tr>
                    <tr>
                      <th className="scope">
                        Desde el 21 de abril de 2025, hasta el 1 de mayo (sin opción a camiseta) o completar las 1500
                        plazas
                      </th>
                      <td>82€</td>
                    </tr>
                    </tbody>
                  </table>
                  <p className='small'>
                    ***la tarifa se incrementa 4€ en caso de elegir la opción
                    de{' '}
                    <Link to='/seguro-devolucion'>“SEGURO DE DEVOLUCIÓN”</Link>{' '}
                    opcional. 7 € en caso de pruebas combinadas.
                  </p>
                  <p className='small'>
                    ***la tarifa se incrementa <u>35 euros</u> en caso de querer
                    el <u>maillot o chaleco</u> conmemorativo, <u>39 euros</u> en caso de querer el maillot de manga
                    larga,
                    <u>29</u> euros en caso de querer la sudadera del evento y <u>55 euros</u>
                    en el caso del <u>culotte</u>.
                  </p>
                  <p className='Rates-info-text text-justify'>
                    <strong>
                      Todo participante inscrito para poder obtener la devolución de su inscripción deberá marcar en la
                      ficha de inscripción la opción “SEGURO DE DEVOLUCIÓN”. Aquellos participantes que no se acojan a
                      este seguro, no tendrán derecho a la devolución de su inscripción en ninguna de las
                      circunstancias.
                    </strong>
                  </p>
                </article>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="Rates-info-img"
                     style={{
                       backgroundImage: `url(${Image2})`,
                       backgroundRepeat: 'no-repeat',
                       backgroundPosition: 'top center',
                       backgroundSize: 'cover',
                       height: '800px',
                       marginRight: '-15px',
                       marginLeft: '-15px'
                     }}>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer/>
        <Media query={{minWidth: 768}}>
          {matches =>
            matches ? (
              ''
            ) : (
              <section className="inscription-fixed-bar">
                <Button className="inscription-fixed"
                        href="https://sportmaniacs.com/c/pontevedra-4-picos-2025"
                        rel="noopener noreferrer">
                  Inscríbete
                </Button>
              </section>
            )}
        </Media>
      </div>
    );
  }
}

export default RatesPage;
